import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { InitialEmbryoState } from './types';

export const initialState: InitialEmbryoState = {
  _id: "",
  embroys: [],
  playerId: "",
  scores: {
    rank: 0,
    suitable: 0,
    discarded: 0,
    total: 0,
    total_time_in_sec: 0,
    score: 0,
    _id: ""
  },
  createdAt: "",
  updatedAt: "",
  __v: 0
};

export const useEmbroySlice = createSlice({
  name: 'embryos',
  initialState,
  reducers: {
    getEmbryoItemList: (state, action: PayloadAction<any>) => {
      state._id = action.payload;
    },
    setEmbryoItem: (state, action: PayloadAction<any>) => {
      state.__v = action.payload.__v
      state.createdAt = action.payload.createdAt
      state.embroys = action.payload.embroys
      state.updatedAt = action.payload.updatedAt
      state._id = action.payload._id
      state.playerId = action.payload.playerId
    },
    updateEmbryoDetail: (state, action: PayloadAction<any>) => {
      const embryo = state.embroys.find(embr => embr.embroy_id === action.payload.id)
      const index = state.embroys.findIndex(embr => embr.embroy_id === action.payload.id)
      console.log({index, res: action.payload.response})
      if (embryo) {
        embryo.response = action.payload.response
      }
    },
    updateEmbryoDetailsTime: (state, action: PayloadAction<any>) => {
      const embryo = state.embroys.find(embr => embr.embroy_id === action.payload.id)
      const index = state.embroys.findIndex(embr => embr.embroy_id === action.payload.id)
      console.log({index, res: action.payload.timeLeft})
      if (embryo) {
        embryo.time_in_sec = action.payload.timeLeft
      }
    },
    putEmbryoList: (state) => {
    },
    updateScorecard: (state, action: PayloadAction<any>) => {
      state.scores = action.payload.scores
      state.embroys = action.payload.embroys
    }
  },
});

export const { reducer, actions, name: sliceKey } = useEmbroySlice;
