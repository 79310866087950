import React from 'react';
import { Box, Grid } from '@mui/material';
import { useStyles } from './styles';
import Right from 'assets/Right';
import Wrong from 'assets/Wrong';
import { TimeInMinute } from 'utils/helpers';

export enum AnswerType {
    transfer = 'Transfer/ Cryo',
    discard = 'Discard'
}
type ExpandableRowContentProps = {
    answerType: AnswerType,
    answerResult: boolean,
    ansPts: number | string,
    time: any,
    timeBonusPt: number,
    imgSrc: string,
}
export const ExpandableRowContent = (
    { answerType,
        answerResult,
        ansPts,
        time,
        timeBonusPt,
        imgSrc
    }
        : ExpandableRowContentProps) => {

    const styles = useStyles();
    return (
        <>
            <Box className={styles.ExpandableRowContainer}>
                <Grid container spacing={2}>
                    <Grid item sm={3} xs={3}>
                        <img src={imgSrc} alt='' />
                    </Grid>
                    <Grid item sm={9} xs={9} className={styles.TimeContainer} sx={{ mt: '5px' }}>
                        <div style={{ width: '79%' }}>
                            <div className={styles.TimeContainer}>
                                <text className={answerType === AnswerType.transfer ? styles.SelectedOptionsAnswered : styles.SelectedOptionsDiscarded}>{answerType}</text>
                                <text>{answerResult ? <Right /> : <Wrong />}</text>
                            </div>
                            <div className={`${styles.BonusTiming} ${styles.TimeContainer}`}>
                                <text>{TimeInMinute(time)}</text>
                                <text>Time Bonus</text>
                            </div>
                        </div>
                        <div style={{ width: '30%', textAlign: 'right' }}>
                            <text>{ansPts !== 0 && ansPts}</text><span className={styles.BonusTiming}> {ansPts === 0 && ansPts} pts</span><br />
                            <text>{timeBonusPt !== 0 && timeBonusPt}</text><span className={styles.BonusTiming}> {timeBonusPt === 0 && timeBonusPt} pts</span><br />
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

type RowProps = {
    label: any,
    value: any,
    outOf: null | any
}
export const RowContent = ({ label, value, outOf }: RowProps) => {
    const styles = useStyles();
    return (
        <Box className={styles.RowContentContainer}>
            <p className={styles.Label}>{label}</p>
            <p className={styles.Value}>{value}{outOf && <span style={{ color: 'rgba(236, 250, 236, 0.5)', fontWeight: 400 }}> / {outOf}</span>}</p>
        </Box>
    )
}