import React, { useEffect, useRef, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Typography } from '@mui/material';

import CompanyNameWithLogo from 'app/components/CompanyNameWithLogo';
import Share from '../../../assets/Share';
import Minus from 'assets/Minus';
import Plus from 'assets/Plus';
import { useStyles } from './styles';
import { AnswerType, ExpandableRowContent, RowContent } from './components/ExpandableRows';
import { useSelector } from 'react-redux';
import { useScreenshot } from "use-react-screenshot";
import { useNavigate, useParams } from 'react-router-dom';
import tryAgainImg from 'assets/TryAgain.png';
import { useDispatch } from 'react-redux';
import { actions, sliceKey, reducer } from './slice';
import { actions as EmbryoActions, sliceKey as EmbryoSlice, reducer as EmbryoReducer } from '../Embryo/slice';
import { useScoreCardSaga } from './saga';
import { useEmbryoSaga } from '../Embryo/saga';
import { actions as alertBoxActions } from '../../../redux/alertSlice'
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { selectDashboardStats } from './selector';
import { copyToClipboard } from './utils';
import { ordinal_suffix_of, TimeInMinute } from 'utils/helpers';
type Props = {};

const Scorecard = (props: Props) => {
  useInjectReducer({
    key: sliceKey,
    reducer: reducer,
  });

  useInjectReducer({
    key: EmbryoSlice,
    reducer: EmbryoReducer,
  });

  useInjectSaga({
    key: sliceKey,
    saga: useScoreCardSaga,
  });

  useInjectSaga({
    key: EmbryoSlice,
    saga: useEmbryoSaga,
  });

  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { id } = useParams()

  const stats = useSelector(selectDashboardStats)

  const [isExpanded, setIsExpanded] = useState(false);
  const handleExpandableChange = () => {
    setIsExpanded(!isExpanded)
  }
  useEffect(() => {
    dispatch(actions.getDashboardStats(id))
  }, [id])

  // ========screenshot=========================================
  const ref = useRef(null);
  const [image, takeScreenShot] = useScreenshot();
  const shareData = {
    // title: 'Scorecard Screenshot',
    // text: `This is my Scorecard! @ ${window.location.href}`,
    // url: image
    title: 'This is my Scorecard.',
    text: `Check out my score for the EQChallenge.fairtility.com. Can you beat me?`,
    url: window.location.href
  }
  const getImage = () => {
    try {
      if (navigator.share) {
        takeScreenShot(ref.current)
        navigator.share(shareData)
      } else {
        copyToClipboard(window.location.href);
        dispatch(alertBoxActions.changeAlertBox({ open: true, message: "Link Copied", variant: 'success' }))
      }
    } catch (e) {
      alert('Cannot share' + e)
    }
  };

  // ==========tryagain========================================
  const handleTryAgain = () => {
    dispatch(EmbryoActions.getEmbryoItemList(id))
    navigate(`/start?games=${id}`)
  }

  // ==========================Request A Demo==============
  const handleRequestADemo = () => {
    dispatch(actions.requestADemoForChloe(stats.playerId))
  }

  if (!stats._id) {
    return null
  }
  return (
    <>
      <Box display="flex" alignItems={'center'} className={styles.Container} ref={ref}>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <div className={styles.TextContainer}>
              <CompanyNameWithLogo />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} sx={{ mt: 5 }}>
            <Box
              className={styles.ScoreCard}
            >
              <p style={{ fontSize: '20px' }}>Score</p>
              <p className={styles.Points}>{stats?.scores?.score}</p>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box
              className={styles.ScoreCard}
            >
              <p className={styles.Label}>Rank</p>
              <p className={styles.Value}>{ordinal_suffix_of(stats?.scores?.rank)}</p>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box
              className={styles.ScorerSection}
            >
              <RowContent label={<p>Transfer/Cryo an <br /> Implanted Embryo</p>} value={stats?.scores?.suitable} outOf={3} />
              <RowContent label={<p>Discarded an <br /> Implanted Embryo</p>} value={stats?.scores?.discarded} outOf={3} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box
              className={styles.ScoreCard}
            >
              <p className={styles.Label}>Total Time</p>
              <p className={styles.Value}>{TimeInMinute(stats?.scores?.total_time_in_sec)}</p>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} >
            <Accordion
              disableGutters
              expanded={isExpanded}
              onChange={handleExpandableChange}
              elevation={0}
              className={styles.ScorerSection}
              style={{ borderBottom: 'none' }}
            >
              <AccordionSummary
                sx={{ p: 0 }}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box className={styles.AccordionSummaryContainer}>
                  <text className={styles.Label}>{'Score Breakdown'}</text>
                  <text className={styles.Value}>{isExpanded ? <Minus /> : <Plus />}</text>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <Typography >
                  {(stats.embroys).map((embroy, index) => {
                    return (
                      <ExpandableRowContent
                        key={index}
                        answerType={embroy.response ? AnswerType.transfer : AnswerType.discard}
                        answerResult={embroy.response === embroy.transfer}
                        ansPts={embroy.player_score}
                        time={embroy.time_in_sec}
                        timeBonusPt={embroy.time_bonus}
                        imgSrc={embroy.last_image_link}
                      />
                    )
                  })}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box className={styles.ButtonContainer}>
              <Button
                variant={'outlined'}
                className={styles.OutLinedButton}
                startIcon={<Share />}
                onClick={getImage}
              >
                Share Results
              </Button>
              <Button
                variant={'outlined'}
                className={styles.OutLinedButton}
                startIcon={<img src={tryAgainImg} width={20} height={20} alt="" />}
                onClick={handleTryAgain}
              >
                Try Again
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} className={styles.ButonAlignment}>
            <Button variant={'contained'} sx={{ height: '72px' }} onClick={handleRequestADemo}>
              {/* <a href='mailto:Demo@fairtility.com' className={styles.ButtonLink}>Request a demo of Chloe</a> */}
              Request a demo of Chloe
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Scorecard;
