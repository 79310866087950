import { colors, createTheme } from '@mui/material';

const theme = createTheme({
  direction: 'ltr',
  palette: {
    background: {
      default: '#1F1F1E',
      paper: 'transparent',
    },
    primary: {
      main: '#E84D09',
    },
    secondary: {
      main: "#009898",
    },
    text: {
      primary: '#ECFAEC',
      secondary: colors.blueGrey[600],
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          // background:
          //   'linear-gradient(90deg, #C6AC73 -13.37%, #9F854C 113.95%)',
          background: '#8BC4C1',
          color: '#1F1F1E',
        },
        root: {
          padding: '10px 30px',
          borderRadius: '0px',
          width: '90%',
          fontWeight: '600',
          fontSize: '18px',
          background: '#8BC4C1',
          color: '#1F1F1E',
          backgroundColor: '#8BC4C1',
          '&:hover': {
            // opacity: '0.2',
            backgroundColor: '#8BC4C1',
            color: '#1F1F1E'
          },
          '&:active': {
            // opacity: '0.2',
            backgroundColor: '#8BC4C1',
            color: '#1F1F1E',
          },
          '&.Mui-disabled': {
            border: '1px solid #8BC4C1',
            opacity: '0.2',
            backgroundColor: '#8BC4C1',
            color: '#1F1F1E',
          }
        },
        outlined: {
          background: 'none',
          color: '#ECFAEC',
          border: '1px solid rgba(236, 250, 236, 0.2)',
          '&:hover': {
            border: '1px solid rgba(236, 250, 236, 0.2)',
            background: 'none',
            // opacity: '0.2',
            color: '#ECFAEC'
          },
          '&:active': {
            border: '1px solid rgba(236, 250, 236, 0.2)',
            // opacity: '0.2',
            backgroundColor: 'none',
            color: '#ECFAEC',
          },
        },
        text: {
          background: 'none',
          border: 'none',
          color: '#8BC4C1',
          '& .css-gcc2o7-MuiButton-startIcon': {
            marginRight: '4px',
            marginLeft: 0,
            marginBottom: '3px'
          }
        }
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: 'rgba(236, 250, 236, 0.1)',
          borderRadius: '0',
          color: '#ECFAEC',
          border: 'none',
          'input[type=text]:focus': {
            color: '#ECFAEC',
            opacity: '1.0 !important',
            border: 'none'
          },
          '& focus': {
            border: 'none'
          },
          '& input:-internal-autofill-selected': {
            backgroundColor: 'rgba(236, 250, 236, 0.1) !important',
          },
          '& input:-internal-input-suggested': {
            backgroundColor: 'rgba(236, 250, 236, 0.1) !important',
          },
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          padding: '0px 0px 5px 0px !important',
          margin: 0,
          background: '#FFFFFF',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
        },
        thumb: {
          display: 'none',
        },
        track: {
          height: '12px',
        },
        rail: {
          color: "#fff",
          opacity: 1,
          height: '12px'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #4B615F'
        }
      }
    }
  },
  typography: {
    allVariants: {
      textTransform: 'none',
      fontFamily: `"Archia", "Roboto", "Helvetica", "Arial", sans-serif`,
    }
  }
});

export default theme;
