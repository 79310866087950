import { makeStyles } from '@mui/styles';
// import BgShape from '../../../assets/bg-shape.png';
import theme from 'styles/theme/mui';

export const useStyles = makeStyles(() => ({
    Container: {
        '& p ': {
            marginTop: '5px',
            marginBottom: '5px'
        }
    },
    TextContainer: {
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '40px',
        '& h3': {
            width: '68%',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '24px',
            lineHeight: '29px',
        },
        '& p': {
            marginTop: '5px',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '130%'
        }
    },
    FooterClass: {
        marginTop: '15%',
        position: 'relative',
        bottom: '10px',
        width: '100%'
    },
    ButonAlignment: {
        textAlign: 'center',
        marginTop: '20px'
    },
    ScoreCard: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderBottom: '1px solid rgba(236, 250, 236, 0.1)',
    },
    ScorerSection: {
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderBottom: '1px solid rgba(236, 250, 236, 0.1)',
        paddingTop: '5px',
        paddingBottom: '5px'
    },
    Points: {
        fontWeight: 600,
        fontSize: '60px',
        lineHeight: '72px',
        textAlign: 'right',
        color: '#ECFAEC',
        marginTop: 0,
        marginBottom: 0,
    },
    Label: {
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '24px',
        color: '#ECFAEC',
        opacity: 0.5,
    },
    Value: {
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '24px',
        textAlign: 'right',
        color: '#ECFAEC',
        marginTop:'auto !important',
        marginBottom:'10px !important',
    },
    ButtonContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '90%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    OutLinedButton: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '18px',
        textAlign: 'center',
        gap: '4px',
        padding: '5px',
        height: '72px',
        width: '48%',
        color: '#ECFAEC',
        borderColor: 'rgba(236, 250, 236, 0.2)',
        '& .css-gcc2o7-MuiButton-startIcon': {
            margin: 0,
            [theme.breakpoints.down(321)]: {
                margin: 'auto'
            }
        }
    },
    SelectedOptionsAnswered: {
        color: '#E84D09',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
    },
    SelectedOptionsDiscarded: {
        color: '#009898',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
    },
    BonusTiming: {
        color: 'rgba(236, 250, 236, 0.5)'
    },
    TimeContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    ExpandableRowContainer: {
        paddingTop: '10px',
        paddingBottom: '10px',
        borderBottom: '1px solid rgba(236, 250, 236, 0.1)',
        '& img': {
            maxWidth: '100%',
            height: 'auto'
        }
    },
    AccordionSummaryContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '5px',
        paddingBottom: '5px'
    },
    RowContentContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '5px',
        paddingBottom: '5px'
    }

}))