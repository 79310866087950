import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { Box, Button, Grid } from '@mui/material';

import CompanyLogo from '../../../assets/companyLogo.png';
import CompanyName from '../../components/CompanyName';
import { useStyles } from './styles';

import { actions, reducer, sliceKey } from '../Embryo/slice';
import { actions as UserIdActions, reducer as UserIdReducers, sliceKey as UserIdSliceKey } from '../../../redux/userIdSlice';
import { actions as AlertActions, reducer as AlertReducers, sliceKey as AlertSliceKey } from '../../../redux/alertSlice';

import { useEmbryoSaga } from '../Embryo/saga';
import { useDispatch, useSelector } from 'react-redux';
import { embroysItem } from '../Embryo/selector';
import { idSelector } from 'redux/selector';

type Props = {};

const Start = (props: Props) => {
  useInjectReducer({
    key: sliceKey,
    reducer: reducer,
  });

  useInjectReducer({
    key: UserIdSliceKey,
    reducer: UserIdReducers,
  });

  useInjectReducer({
    key: AlertSliceKey,
    reducer: AlertReducers,
  });

  useInjectSaga({
    key: sliceKey,
    saga: useEmbryoSaga,
  });

  const styles = useStyles();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();
  const embroysListItem = useSelector(embroysItem);
  const userId = useSelector(idSelector)

  useEffect(() => {
    if (location.search) {
      const newId = location.search.split("=")[1];
      dispatch(UserIdActions.changeUserId(newId))
      dispatch(actions.getEmbryoItemList(newId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])


  const handleSubmit = () => {
    if (userId && embroysListItem && embroysListItem.embroys.length > 0) {
      let path = '/embryo';
      navigate(path);
    } else {
      dispatch(AlertActions.changeAlertBox({
        open: true,
        message: "Something went wrong.",
        variant: 'error'
      }))
    }
  }

  return (
    <>
      <Box display="flex" alignItems={'center'}>
        <Grid container>
          <Grid item xs={12} sm={12} className={styles.Companylogo}>
            <img src={CompanyLogo} alt="" />
          </Grid>
          <Grid item xs={12} sm={12} style={{ textAlign: 'center', marginTop: '10px' }}>
            <CompanyName />
          </Grid>
          <Grid item xs={12} sm={12} style={{ textAlign: 'center', marginTop: '40px' }}>
            <p className={styles.Heading}>Instructions</p>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div className={styles.ParaContainer}>
              <p>Review each embryo and select <span style={{ color: '#E84D09' }}>Transfer/Cryo</span> if you predict the embryo has implantation potential, or <span style={{ color: '#009898' }}>Discard</span> if not.</p>
            </div>
            <div className={styles.ParaContainer}>
              <p>Use the <span style={{ color: '#ED7600' }}>Chloe EQ Score</span> and <span style={{ color: '#ED7600' }}>Chloe Insights</span> to get additional information about each embryo.</p>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} style={{ textAlign: 'center' }}>
            <Button className={styles.CustomeButton} onClick={handleSubmit}>
              Begin
            </Button>
          </Grid>
        </Grid>
      </Box>
      {/* <Footer className={styles.FooterClass} /> */}
    </>
  );
};

export default Start;
