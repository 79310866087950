import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { TopTenItem, TopTenListState } from './types';

export const initialState: TopTenListState = {
  List: []
};

export const useDashboardSlice = createSlice({
  name: 'topTenList',
  initialState,
  reducers: {
    // getTopTenLists: (state, action: PayloadAction<TopTenItem[]>) => {
    getTopTenLists: (state) => {
      return state
    },
    setTopTenLists: (state, action: PayloadAction<TopTenItem[]>) => {
      state.List = action.payload
    }
  },
});

export const { reducer, actions, name: sliceKey } = useDashboardSlice;
