import { getDefaultHeadersChloe } from 'utils/helpers';
import { request } from 'utils/request';
import { APP_API_URL } from 'utils/constants';
import { put, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { actions as alertBoxActions } from '../../../redux/alertSlice'
import { actions as isLoadingActions } from '../../../redux/isLoadingSlice'
import { actions as playerIdActions } from '../../../redux/playerIdSlice'

export function* postUserInformation(action) {
  console.log(action, actions, "this is userInfo")
  console.log(isLoadingActions, "this is isLoading")
  console.log(alertBoxActions, "this is alertbox")
  yield put(isLoadingActions.changeisLoading(true));
  try {
    const options = {
      method: 'POST',
      headers: getDefaultHeadersChloe(),
      body: JSON.stringify(action.payload)
    }
    const response = yield request(
      `${APP_API_URL}/users`,
      options
    );
    if (response) {
      yield put(isLoadingActions.changeisLoading(false));
      if (response.msg.toLowerCase().includes("exist")) {
        yield put(alertBoxActions.changeAlertBox({ open: true, message: response.msg, variant: 'error' }));
        return;
      }
      yield put(alertBoxActions.changeAlertBox({ open: true, message: response.msg, variant: 'success' }));
      const userKey = response.data
      yield put(playerIdActions.changePlayerId(userKey))
      return;
    } else {
      yield put(isLoadingActions.changeisLoading(false));
      yield put(alertBoxActions.changeAlertBox({ open: true, message: "Something went wrong.", variant: 'error' }));
    }
  } catch (e: any) {
    yield put(isLoadingActions.changeisLoading(false));
    yield put(alertBoxActions.changeAlertBox({ open: true, message: "Something went wrong.", variant: 'error' }));
  }
}

export function* useRegistrationSaga() {
  yield takeLatest(actions.sendRegistrationData.type, postUserInformation);
  // yield takeLatest(actions.sendRegistrationData.type, resendEmail);
}