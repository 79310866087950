import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { InitialIsLoadingState } from "app/containers/Registration/types";

export const initialState: InitialIsLoadingState = {
    isLoading: false,
}

export const isLoadingChange = createSlice({
    name: 'isLoading',
    initialState,
    reducers: {
        changeisLoading: (state, action: PayloadAction<any>) => {
            console.log(state, "This is main state")
            state.isLoading = action.payload;
            return state
        },
    }
})

export const { reducer, actions, name: sliceKey } = isLoadingChange