import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { InitialAlertBoxState } from "app/containers/Registration/types";

export const initialState: InitialAlertBoxState = {
    alertBox: {
        open: false,
        message: "",
        variant: 'error'
    },
};

export const alertBoxChange = createSlice({
    name: 'alertBox',
    initialState,
    reducers: {
        changeAlertBox: (state, action: PayloadAction<any>) => {
            state.alertBox = { ...action.payload };
        },
    }
})

export const { reducer, actions, name: sliceKey } = alertBoxChange;