import { DateTime } from 'luxon';
import { addPermissions, editPermissions } from 'utils/constants';
import { EDIT_USER_ROLES, UserTypeEnum } from 'utils/UserType.enum';

export const handleChange = (event, state, stateUpdater) => {
  const { name, value } = event.target;

  if (typeof state === 'object') {
    stateUpdater({ ...state, [name]: value });
  } else {
    stateUpdater(value);
  }
};

export const emailValidator = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const positionValidator = position => {
  return String(position)
    .toLowerCase()
    .match(
      /%^[."a-zA-Z- ]{1,50}$/,
    );
};

export const nameValidator = name => {
  const newName = name.split(" ").join("")
  return String(newName)
    .toLowerCase()
    .match(
      /^[a-zA-Z]+([a-zA-Z]+)+$/,
    );
};

export const getFormattedDateTime = (
  date: any,
  format: string = 'dd-MM-yyyy, hh:mm a',
) => {
  return DateTime.fromISO(date).toFormat(format);
};

// ==========================defaultHeader===================
export const getDefaultHeadersChloe = () => {
  return {
    'Content-Type': 'application/json',
  }
}
export const getDefaultHeaders = () => {
  return {
    'Content-Type': 'application/json',
  };
};

export const removeBlankProperties = (obj: object) => {
  return JSON.parse(JSON.stringify(obj, replaceUndefinedOrNull));
};

export function replaceUndefinedOrNull(key, value) {
  if (value === null || value === undefined || value === '') {
    return undefined;
  }

  return value;
}

/**
 * returns logged in user is
 * @param
 */

export const hasPermissions = (type = 'edit') => {
  let result: any = null;
  const storageString = localStorage.getItem('loginResponse');
  const userDetails = storageString ? JSON.parse(storageString) : null;
  const userType = userDetails ? userDetails.userType : null;
  if (!userType) {
    return
  }
  if (type === 'edit') {
    result = editPermissions.includes(UserTypeEnum[`${userType}`]);
  } else {
    result = addPermissions.includes(UserTypeEnum[`${userType}`]);
  }
  return result
};

export const TimeInMinute = (seconds) => {
  return new Date(seconds * 1000).toISOString().slice(14, 19)
}

export const ordinal_suffix_of = (i: number) => {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
}