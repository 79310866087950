import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import Chloe from '../../../assets/Chloe.png';
import CompanyLogo from '../../../assets/companyLogo.png';

type Props = {

};

const useStyles = makeStyles(() => ({
    Container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    CompanyLogo: {
        width: '80px !important',
        height: '80px !important',
    },
    CompanyNameContainer: {
        marginLeft: '10px',
        width: '35%'
    },
    companyNameImage: {
        width: '70%'
    },
    h6Styles: {
        margin: '0px',
        padding: '0px',
        color: '#E84D09',
        fontWeight: 400,
    }
}))

const CompanyNameWithLogo = (props: Props) => {
    const styles = useStyles();
    const { t } = useTranslation();
    return <div className={styles.Container}>
        <img src={CompanyLogo} className={styles.CompanyLogo} alt='' />
        <div className={styles.CompanyNameContainer}>
            <img className={styles.companyNameImage} src={Chloe} alt='' />
            <h6 className={styles.h6Styles}>Embryo Challenge</h6>
        </div>
    </div>
};

export default CompanyNameWithLogo;
