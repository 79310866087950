import { makeStyles } from '@mui/styles';
// import BgShape from '../../../assets/bg-shape.png';

export const useStyles = makeStyles(() => ({
    Companylogo: {
        '& img': {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '15%',
            width: '20%',
            marginBottom: '10px'
        }
    },
    'video:[-internal-media-controls-button-panel]': {
        height: '80%'
    },
    pause: {
        position: 'absolute',
        backgroundColor: '#fff',
        top: '45%',
        left: '48%',
    },
    WelcomeScreenContainer: {
        position: 'relative',
        'video::[-internal-media-controls-button-panel]': {
            height: '100%'
        },
        '& .pause': {
            position: 'absolute',
            backgroundColor: '#fff',
            top: '45%',
            left: '48%',
        }
    },
    CustomeButton: {
        padding: '10px 30px',
        background: '#8BC4C1',
        color: '#1F1F1E',
        borderRadius: '0px',
        width: '90%',
        fontWeight: '600',
        fontSize: '18px',
        '&:hover': {
            background: '#8BC4C1',
            opacity: '0.2'
        }
    },
    TextContainer: {
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '40px',
        '& h3': {
            width: '68%',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '24px',
            lineHeight: '29px',
        },
        '& p': {
            marginTop: '5px',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '130%'
        }
    },
    CustomeOutlineButton: {
        padding: '10px 30px',
        border: '1px solid rgba(236, 250, 236, 0.2)',
        color: '#ECFAEC',
        borderRadius: '0px',
        width: '90%',
        fontWeight: '600',
        fontSize: '18px',
        '&:hover': {
            background: '#8BC4C1',
            opacity: '0.2'
        }
    },
    ButonAlignment: {
        textAlign: 'center',
        marginTop: '20px',
        '& img': {
            marginRight: '15px'
        }
    },
    FooterClass: {
        marginTop: '15%',
        bottom: '10px',
        width: '100%'
    },
    PlaceholderStyles: {
        fontFamily: 'Archia',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '100%',
        letterSpacing: '0.2em',
        textTransform: 'uppercase',
        color: '#ECFAEC',
    },
    ErrorMessage: {
        fontFamily: 'Archia',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '100%',
        color: '#E84D09',
    },
    ProgressBarStatus: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
        '& p': {
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '14px',
            letterSpacing: '0.1em',
            textTransform: 'uppercase',
            color: '#ECFAEC',
            opacity: 0.5,
            '& text': {
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '14px',
                textAlign: 'right',
                color: '#ECFAEC',
                opacity: 1.0
            }
        },
    },
    Timer: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '14px',
        textAlign: 'right',
        color: '#ECFAEC',
        opacity: '1',
        marginLeft: '5px'
    },
    ButtonContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '90%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    Transfer: {
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '24px',
        textAlign: 'center',
        color: '#1F1F1E',
        padding: '10px 30px',
        gap: '10px',
        background: '#E84D09',
        height: '72px',
        width: '48%',
        '&:hover': {
            background: '#E84D09',
            color: '#1F1F1E',
        },
        '&:active': {
            background: '#E84D09',
            color: '#1F1F1E',
        },
    },
    Discard: {
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '24px',
        textAlign: 'center',
        color: '#1F1F1E',
        padding: '10px 30px',
        gap: '10px',
        background: '#009898',
        height: '72px',
        width: '48%',
        '&:hover': {
            background: '#009898',
            color: '#1F1F1E',
        },
        '&:active': {
            background: '#009898',
            color: '#1F1F1E',
        },
    },
    ParaDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    ScorerSection: {
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '0px',
        border: '1px solid rgba(236, 250, 236, 0.2)',
        borderRadius: '0px !important'
    },
    '[MuiGrid-root]': {
        '& .MuiCardMedia-media': {

        },
        '& video': {
            backgroundColor: 'azure'
        }
    },
    'MuiCardMedia-root': {
        background: 'azure'
    },
    PauseBox: {
        width: '84px',
        height: '84px',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: '#E84D09',
        borderRadius: '50%',
        '& img': {
            position: 'absolute',
            top: '25%',
            left: '35%',
        }
    },
    LabelContainerMain: {
        position: 'absolute',
        top: '0px',
        right: 0,
        padding: '0px',
        background: 'rgba(139, 196, 193, 0.75)'
    },
    LabelContainer: {
        padding: '6px 12px',
        gap: '5px',
        '& p': {
            padding: 0,
            margin: 0,
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '14px',
            textAlign: 'center',
            letterSpacing: '0.1em',
            textTransform: 'uppercase',
            color: 'rgba(31, 31, 30, 0.5)',
            '& span': {
                color: 'rgba(31, 31, 30, 1.0)'
            }
        }
    }
}))