import { NoEncryption } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
// import BgShape from '../../../assets/bg-shape.png';
import theme from 'styles/theme/mui';

export const useStyles = makeStyles(() => ({
    Companylogo: {
        '& img': {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '15%',
            width: '20%',
            marginBottom: '10px'
        }
    },
    WelcomeScreenContainer: {

    },
    CustomeButton: {
        padding: '10px 30px',
        background: '#8BC4C1',
        color: '#1F1F1E',
        borderRadius: '0px',
        width: '90%',
        fontWeight: '600',
        fontSize: '18px',
        '&:hover': {
            background: '#8BC4C1',
            opacity: '0.2'
        }
    },
    FooterClass: {
        position: 'absolute',
        bottom: '10px',
        width: '100%',
        marginTop: '10%'
    },
    WelcomePara: {
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '40px'
    }
}))