import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { PlayerIdState } from '../Registration/types';

export const initialState: PlayerIdState = {
  playerId: "",
};
export const resendEmail = createSlice({
  name: 'playerId',
  initialState,
  reducers: {
    resendEmailData: (state, action: PayloadAction<any>) => {
      state.playerId = action.payload;
    },
    updatePlayerId: (state) => {
      debugger
      state.playerId = "";
    },
  }
})

export const { reducer, actions, name: sliceKey } = resendEmail;
