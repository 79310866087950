import React from 'react';
import { Box, Button, Grid } from '@mui/material';

import Footer from 'app/components/Footer';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

type Props = {};

const useStyles = makeStyles(() => ({
  Companylogo: {
    '& img': {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '15%',
      width: '20%',
      marginBottom: '10px'
    }
  },
  WelcomeScreenContainer: {

  },
  CustomeButton: {
    padding: '10px 30px',
    background: '#8BC4C1',
    color: '#1F1F1E',
    borderRadius: '0px',
    width: '90%',
    fontWeight: '600',
    fontSize: '18px',
  },
  TextContainer: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '40px',
    '& h3': {
      width: '68%',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '29px',
    },
    '& p': {
      marginTop: '5px',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '130%'
    },
    '& a': {
      color: '#ECFAEC !important',
      textDecoration: 'none'
    }
  },
  CustomeOutlineButton: {
    padding: '10px 30px',
    border: '1px solid rgba(236, 250, 236, 0.2)',
    borderRadius: '0px',
    width: '90%',
    fontWeight: '600',
    fontSize: '18px',
    color: '#8BC4C1',
    background: 'none !important',
    '&:hover': {
      background: '#8BC4C1',
      border: '1px solid rgba(236, 250, 236, 0.2)',
      opacity: '0.2'
    }
  },
  FooterClass: {
    marginTop: '10%',
    width: '100%',
    position: 'absolute',
    bottom: '10px'
  }
}))

const LegalPage = (props: Props) => {

  const styles = useStyles();

  let navigate = useNavigate();
  const goToRegistration = () => {
    let path = '/registration';
    navigate(path);
  }

  const goToWelcome = () => {
    let path = '/';
    navigate(path);
  }

  return (
    <>
      <Box display="flex" alignItems={'center'} className={styles.WelcomeScreenContainer}>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <div className={styles.TextContainer}>
              <h3 style={{ color: '#fff' }}>Legal and Privacy Disclaimer</h3>
              <p>
                By providing us your personal information you agree to our {" "}
                <a href='https://fairtility.com/privacy/eqc-terms/' rel="noreferrer" style={{ textDecoration: 'underline' }} target="_blank">Terms and Conditions</a>
                {" "} and <a href='https://fairtility.com/privacy/eqc/' rel="noreferrer" style={{ textDecoration: 'underline' }} target="_blank">Privacy Policy</a>.
                {" "} We will use your personal information to enable you to use the CHLOE EQ Challenge and for communication and marketing purposes.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} style={{ textAlign: 'center', marginTop: '40px' }}>
            <Button className={styles.CustomeButton} onClick={goToRegistration}>
              Agree
            </Button>
            <Button variant={'outlined'} sx={{ marginTop: '10px' }} onClick={goToWelcome}>
              Disagree
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default LegalPage;
