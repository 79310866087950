/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, Slider, } from '@mui/material';
import PlayButton from '../../../../assets/PlayButton.png';
import PauseButton from '../../../../assets/pauseButton.png';
import { useStyles } from './styles';
import useVideoPlayer from './hooks/videoPlayerHook';
import { actions as loaderAction, reducer as loaderReducer, sliceKey as loaderSlice } from '../../../../redux/isLoadingSlice';
import { actions as alertAction, reducer as alertReducer, sliceKey as alertSlice } from '../../../../redux/alertSlice';
import { useInjectReducer } from 'utils/redux-injectors';
import { useDispatch } from 'react-redux';

type Props = {
    Sources: string,
    Score: number,
    ThumbnailImg: string;
    embryoId: string
};

const VideoPlayer = ({ embryoId, Sources, Score, ThumbnailImg }: Props) => {
    const styles = useStyles();
    const vidRef = useRef<any>(null);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    useInjectReducer({
        key: loaderSlice,
        reducer: loaderReducer,
    });
    useInjectReducer({
        key: alertSlice,
        reducer: alertReducer,
    });
    const {
        playerState,
        togglePlay,
        handleOnTimeUpdate,
        handleVideoProgress,
        updatePlayerState
    } = useVideoPlayer(vidRef);

    useEffect(() => {
        updatePlayerState({
            isPlaying: false,
            progress: 0,
            speed: 1,
            isMuted: false,
        })
    }, [embryoId])

    useEffect(() => {
        setIsLoading(true)
        dispatch(loaderAction.changeisLoading(true))
        vidRef.current.currentTime = 0;
        updatePlayerState({
            isPlaying: false
        })
    }, [Sources]);

    const handleVideoEnded = () => {
        togglePlay();
        vidRef.current.load()
    }

    const handleOnLoadedData = (e)=>{
        setIsLoading(false);
        dispatch(loaderAction.changeisLoading(false));
    }

    useEffect(()=>{
        if(vidRef?.current?.readyState === 4){
            dispatch(loaderAction.changeisLoading(false));
        }
        if(vidRef?.current?.error){
            debugger;
            dispatch(loaderAction.changeisLoading(false));
        }
    },[vidRef?.current?.readyState])

    if(vidRef?.current?.error){
        dispatch(loaderAction.changeisLoading(false));
        dispatch(alertAction.changeAlertBox({
            open: true,
            message: "Video not found!",
            variant: 'error'
        }))
    }

    return (
        <Grid item xs={12} sm={12} sx={{ position: 'relative' }}>
            <video width="320" height="240"
                className={styles.Video}
                onLoadedMetadata={handleOnLoadedData}
                onLoadedData={handleOnLoadedData}
                preload='auto'
                muted={true}
                controls={false}
                onClick={togglePlay}
                src={Sources}
                ref={vidRef}
                playsInline
                onEnded={handleVideoEnded}
                onError={togglePlay}
                onTimeUpdate={handleOnTimeUpdate}
                poster={ThumbnailImg}
                style={{ backgroundImage: `url(${ThumbnailImg})`, backgroundSize: '250px 250px', backgroundRepeat: 'no-repeat', backgroundPositionX: 'center' }}
            />

            {!isLoading &&
                <Box className={styles.PausePlayContainer} onClick={togglePlay}>
                    <Box className={styles.PauseBox}>
                    {!playerState.isPlaying ?
                        <img src={PlayButton} alt='' />:
                        <img src={PauseButton} alt=''/>
                    }
                    </Box>
                </Box>
            }

            <Box className={styles.LabelContainerMain} >
                <Box className={styles.LabelContainer}>
                    <p>Chloe EQ Score: <span>{Score}</span></p>
                </Box>
            </Box>

            {!isLoading &&
            <Slider
                className={styles.ProgressbarAudio}
                aria-label="Default"
                valueLabelDisplay="auto"
                value={playerState.progress}
                onChange={e => handleVideoProgress(e)}
            />}
        </Grid>
    );
};

export default VideoPlayer;
