import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { PlayerIdState } from "app/containers/Registration/types";

export const initialState: PlayerIdState = {
    playerId: ''
};

export const setPlayerId = createSlice({
    name: 'playerId',
    initialState,
    reducers: {
        changePlayerId: (state, action: PayloadAction<any>) => {
            state.playerId = action.payload;
        },
    }
})

export const { reducer, actions, name: sliceKey } = setPlayerId;