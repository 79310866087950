import { makeStyles } from '@mui/styles';
// import BgShape from '../../../assets/bg-shape.png';

export const useStyles = makeStyles(() => ({
    Container: {
        '& p ': {
            marginTop: '5px',
            marginBottom: '5px'
        }
    },
    TableContainer: {
        marginLeft: 'auto', marginRight: 'auto',
        padding: '78px 91px',
        textAlign: 'center',
        '& p': {
            fontFamily: 'Archia',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '51px',
            lineHeight: '61px',
            '& span': {
                color: '#ED7600',
                fontWeight: 400,
                fontSize: '51px',
            }
        },
        '& .css-smxkeg-MuiPaper-root-MuiTableContainer-root': {
            backgroundColor: 'transparent !important'
        }
    },
    TableFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '50px',
        '& div': {
            display: 'flex',
            justifyContent: 'flex-start'
        },
        '& p': {
            fontWeight: 400,
            fontSize: '20px !important',
            lineHeight: '24px !important',
            textAlign: 'center',
            color: '#ECFAEC',
        },
        '& img': {
            marginRight: '6px',
            width: '30.5px',
            height: '30.5px'
        }
    },
    CodeScannerContainer: {
        marginLeft: 'auto', marginRight: 'auto',
        padding: '78px 0px',
        height: '1080px',
        backgroundColor: '#ECFAEC',
        textAlign: 'center',
        '& p': {
            fontFamily: 'Archia',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '32px',
            lineHeight: '61px',
            color: '#1F1F1E',
            width: '70%',
            marginTop: '25px',
            marginBottom: '25px',
            marginLeft: 'auto',
            marginRight: 'auto',

            '& a': {
                textDecoration: 'none',
                fontWeight: '600',
                color: '#1F1F1E',
            }
        },
        '& .css-smxkeg-MuiPaper-root-MuiTableContainer-root': {
            backgroundColor: 'transparent !important'
        }
    },
    CodeSection: {
        height: '773px',
        marginBottom: '25px',
        '& img': {
            width: '370px',
            height: '370px'
        }
    },
    InfoSection: {
        height: '307px',
        background: '#E84D09',
        position: 'relative',
        display: 'flex',
        padding: '20px',
        '& p': {
            width: '65%',
            fontWeight: 300,
            fontSize: '32px',
            lineHeight: '130%',
            color: '#ECFAEC',
            marginTop: 'auto',
            marginBottom: 'auto'
        },
        '& a': {
            textDecoration: 'none',
            fontWeight: '600',
            color: '#1F1F1E',
        }

    },
    LeaderBoardCornerImage: {
        // position: 'absolute',
        // right: 20,
        // bottom: 20,
        marginTop: 'auto'
    }

}))