import { UserTypeEnum } from "./UserType.enum";
import { StatusType } from "./enum";

export const APP_API_URL = process.env.REACT_APP_API_URL || 'https://dev.chloeeqchallenge.zangula.net/api';
export const APP_VERSION = process.env.REACT_APP_VERSION || '';
export const APP_GOOGLE_MAP_API_KEY = 'AIzaSyDs3PuvlX0H3B6hnvo4FmUEhP25dUg7fRI';
export const APP_PUBLIC_URL = process.env.PUBLIC_URL || '';
export const IS_PRODUCTION = process.env.NODE_ENV !== 'production';
export const IS_DEVELOPMENT = process.env.REACT_APP_ENV === 'development';
export const APP_SOCKET_URL = process.env.REACT_APP_SOCKET_URL || '';
export const API_URL = IS_DEVELOPMENT ? "http://localhost:3000/api" : APP_API_URL
export const LOGIN_HEADER_HEIGHT = 96
export const DASHBOARD_MENU_WIDTH = 267
export const DASHBOARD_TOP_BAR_HEIGHT = 116;
export const DASHBOARD_MENU_CLOSE_WIDTH = 96;
export const DASHBOARD_TOP_MENU_CLOSE_WIDTH = 48;
export const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z-]+$/;

export const financeEmail = "finance@chloeeqchallenge.co.il"
export const siteFilterOptions = [
  '',
  'Wineshop in Town',
  'Tel Aviv Hospital',
  'Ramawat Mall '
]
export const statusFilterOptions = [
  '',
  'Approved', 'In Examination', 'New Request'
]
export const requestChangeRequestTypeFilterOptions = [
  '',
  'Add New Site', 'User Editing', 'New Mobile User', 'Delete'
]

export const requestChangeStatusFilterOptions = [
  '',
  'Approved', 'New Change Request', 'Rejected', 'In Examination',
]

export const productFilterOptions = [
  '', 'Repair Service Renewal'
]
export const customerServiceFilterOptions = [
  '',
]
export const serviceRequestStatusFilterOptions = [
  '', 'New', 'Taken Care of', 'Irrelevant'
]

export const requestTypeFilterOptions = [
  '', 'New Site Request'
]

export const technicalRequestTypeFilterOptions = [
  '',
  'Installation',
  'Repair of the alarm system',
  'Camera system repair'
]

export const technicalRequestStatusFilterOptions = [
  '',
  'New',
  'Taken care of',
  'Irrelevant'
]

export const purchaseStatusFilterOptions = [
  '',
  'New',
  'Inserted for billing',
  'Credit Charged',
  'Cancelled'
]

export const cityFilterOptions = ['Haifa'];

export const readingTypeFilterOptions = [
  '',
  'Installation',
  'Repair of the alarm system',
  'Camera System Repair',
]

export const serviceRequestType = [
  '', 'Sales Enquiries', 'Financial Inquiries', 'Technical Support', 'Public Inquiries'
]

export const technicalStatusFilterOptions = [
  '', 'New', 'Closed', 'Cancelled'
];

export const changeRequestStatusFilterOptions = [
  '', 'APPROVED', 'IN_EXAMINATION', 'NEW_REQUEST', 'CANCELED'
]
// export const changeRequestStatusFilterOptions = [
//   '', 'Approved', 'In Examination', 'New Request', 'Rejected'
// ]

export const callTypeFilterOptions = [
  '',
  'Sales', 'Financial Inquiries', 'Technical Support', 'Public Inquiries', 'Financial Inquiries',
]

export const statusTypeFilterOptions = [{ id: StatusType.NEW, value: "New" }, { id: StatusType.CLOSED, value: "Closed" }, { id: StatusType.CANCELED, value: "Canceled" }, { id: StatusType.IRRELEVENT, value: "Irrelevent" }, { id: StatusType.TAKEN_CARE_OF, value: "Taken care of" }]

export const userStatusFilterOptions = [
  '',
  'Active', 'Off',
]
export const permissionFilterOptions = [
  { id: '', value: '' },
  { id: UserTypeEnum.MANAGER, value: 'Manager', label: "MANAGER" }, { id: UserTypeEnum.SECRETARY, value: 'Secretary', label: "SECRETARY" },
]

export const positionFilterOptions = [
  '', 'Sales', 'New user'
]

export const timingsForUI =
{
  timeOne: '8-10',
  timeTwo: '10-12',
  timeThree: '12-14',
  timeFour: '14-16',
  timeFive: '16-18',
  any: 'Any Time',
}

export const timings =
{
  timeOne: '08:00 - 10:00',
  timeTwo: '10:00 - 12:00',
  timeThree: '12:00 - 14:00',
  timeFour: '14:00 - 16:00',
  timeFive: '16:00 - 18:00',
  any: 'Any Time',
}

export const openingHours = [
  {
    id: 'SUNDAY',
    day: 'Sunday',
    timings,
    timingsForUI
  },
  {
    id: 'MONDAY',
    day: 'Monday',
    timings,
    timingsForUI
  },
  {
    id: 'TUESDAY',
    day: 'Tuesday',
    timings,
    timingsForUI
  },
  {
    id: 'WEDNESDAY',
    day: 'Wednesday',
    timings,
    timingsForUI
  },
  {
    id: 'THURSDAY',
    day: 'Thursday',
    timings,
    timingsForUI
  },
]

export const editPermissions = [
  UserTypeEnum.SUPER_ADMIN, UserTypeEnum.MANAGER
]

export const addPermissions = [
  UserTypeEnum.SUPER_ADMIN, UserTypeEnum.MANAGER
]

export const userAccountId = localStorage.getItem('token');