/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */


import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { selectDirection, themeActions } from 'styles/theme/slice';
import useWindowDimensions from 'utils/hooks/useWindowDimensions';
import Footer from './components/Footer';
import Calculating from './containers/Calculating';
import ConfirmRegistration from './containers/ConfirmRegistration';
import { playerIdSelector } from 'redux/selector';
// import PrivateRoute from '../utils/PrivateRoute';
import DashboardPage from './containers/Dashboard';
import Embryo from './containers/Embryo';
import LegalPage from './containers/Legal';
import Registration from './containers/Registration';
import Scorecard from './containers/Scorecard';
import Start from './containers/Start';
import Leaderboard from './containers/Leaderboard';


import { UnsupportedScreen } from './containers/UnsupportedScreen/Loadable';
import { makeStyles } from '@mui/styles';
import AlertBox from './components/AlertBox';
import Spinner from './components/Spinner';

const useStyles = makeStyles(() => ({
  AppContainer: {
    minHeight: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
  }
}))

export function App() {
  const { width } = useWindowDimensions();
  const direction = useSelector(selectDirection);
  const dispatch = useDispatch();
  const styles = useStyles();
  const playerId = useSelector(playerIdSelector);
  const selectedLanguage = useMemo(() => {
    return {
      language: localStorage.getItem('i18nextLng'),
      direction
    }
  }, [direction]);

  const toggleDirection = () => {
    dispatch((themeActions.toggleDirection()))
  }

  useEffect(() => {
    if (selectedLanguage) {
      if (selectedLanguage.language === 'hw' && selectedLanguage.direction !== 'rtl') {
        toggleDirection()
      }
      if (selectedLanguage.language === 'en' && selectedLanguage.direction !== 'ltr') {
        toggleDirection()
      }
    }
    // eslint-disable-next-line
  }, [selectedLanguage])


  if (width > 720) {
    if (window.location.pathname === "/leaderboard") return <Leaderboard />
    else return <UnsupportedScreen />
  }


  return (
    <BrowserRouter>
      <Helmet titleTemplate="%s - ChloeEqChallenge-chloeeqchallenge" defaultTitle="ChloeEqChallenge-chloeeqchallenge">
        <meta name="description" content="A Chloe-eq-challenge application" />
      </Helmet>
      <div dir={direction} className={styles.AppContainer} >
        <Routes>
          <Route path="/" element={<DashboardPage />} />
          <Route path="/legal" element={<LegalPage />} />
          <Route path="/registration" element={playerId ? <ConfirmRegistration /> : <Registration /> } />
          <Route path="/start" element={<Start />} />
          <Route path="/calculating" element={<Calculating />} />
          <Route path="/embryo" element={<Embryo />} />
          <Route path="/score/:id" element={<Scorecard />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
        </Routes>
        <Footer />
        <AlertBox />
        <Spinner />
      </div>
    </BrowserRouter>
  );
}
