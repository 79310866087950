import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { InitialRegistrationState } from './types';

export const initialState: InitialRegistrationState = {
  userInformation: {
    name: "",
    email: "",
    nickName: "",
    company: "",
    position: "",
  },
};
export const postUserInformation = createSlice({
  name: 'registrationState',
  initialState,
  reducers: {
    sendRegistrationData: (state, action: PayloadAction<any>) => {
      state.userInformation = { ...action.payload };
    },
  }
})

export const { reducer, actions, name: sliceKey } = postUserInformation;
