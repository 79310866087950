import { PayloadAction } from '@reduxjs/toolkit';
import { InitialUserIdState } from 'app/containers/Registration/types';
import { createSlice } from 'utils/@reduxjs/toolkit';

export const initialState: InitialUserIdState = {
    userId: ''
};

export const setUserId = createSlice({
    name: 'userId',
    initialState,
    reducers: {
        changeUserId: (state, action: PayloadAction<any>) => {
            state.userId = action.payload;
        },
    }
})

export const { reducer, actions, name: sliceKey } = setUserId;