import { getDefaultHeaders, getDefaultHeadersChloe, getFormattedDateTime } from 'utils/helpers';
import { request } from 'utils/request';
import { API_URL, APP_API_URL } from 'utils/constants';
import { put, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { actions as alertBoxActions } from '../../../redux/alertSlice'
import { actions as isLoadingActions } from '../../../redux/isLoadingSlice'

export function* getTopTenRank(action) {

  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeadersChloe(),
    }
    const response = yield request(
      `${APP_API_URL}/users/rankers`,
      options
    );
    if (response) {
      console.log(response, "This is response")
      yield put(actions.setTopTenLists(response))
      yield put(isLoadingActions.changeisLoading(false));
      yield put(alertBoxActions.changeAlertBox({ open: true, message: 'Records Loaded Successfully.', variant: 'success' }));
    } else {
      yield put(isLoadingActions.changeisLoading(false));
      yield put(alertBoxActions.changeAlertBox({ open: true, message: "Something went wrong.", variant: 'error' }));
    }
  } catch (e: any) {
    yield put(isLoadingActions.changeisLoading(false));
    yield put(alertBoxActions.changeAlertBox({ open: true, message: "Something went wrong.", variant: 'error' }));
  }
}

export function* useTopTenRankSaga() {
  yield takeLatest(actions.getTopTenLists.type, getTopTenRank);
  // yield takeLatest(actions.sendRegistrationData.type, resendEmail);
}
