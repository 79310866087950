import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { Box, Button, Grid } from '@mui/material';
import { reducer, sliceKey } from './slice';
import { useDashboardSaga } from './saga';
import CompanyLogo from '../../../assets/companyLogo.png';
import CompanyName from '../../components/CompanyName';
import { useStyles } from './styles';

type Props = {};

const DashboardPage = (props: Props) => {
  useInjectReducer({
    key: sliceKey,
    reducer: reducer,
  });

  useInjectSaga({
    key: sliceKey,
    saga: useDashboardSaga,
  });

  const styles = useStyles();
  let navigate = useNavigate();
  const routeToLegal = () => {
    let path = '/legal';
    navigate(path);
  }

  return (
    <>
      <Box display="flex" alignItems={'center'} className={styles.WelcomeScreenContainer}>
        <Grid container>
          <Grid item xs={12} sm={12} className={styles.Companylogo}>
            <img src={CompanyLogo} alt="" />
          </Grid>
          <Grid item xs={12} sm={12} style={{ textAlign: 'center', marginTop: '10px' }}>
            <CompanyName />
          </Grid>
          <Grid item xs={12} sm={12} style={{ textAlign: 'center' }}>
            <div style={{}} className={styles.WelcomePara}>
              <p>Test yourself with the help of artificial intelligence to see how quickly and accurately you can predict implantation outcomes.</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} style={{ textAlign: 'center', marginTop: '30px', marginBottom: '30px' }}>
            <Button className={styles.CustomeButton} onClick={routeToLegal}>
              Start
            </Button>
          </Grid>
        </Grid>
      </Box>
      {/* <Footer className={styles.FooterClass} /> */}
    </>
  );
};

export default DashboardPage;
