import React from 'react';
import { Box, Button, Grid } from '@mui/material';

import CompanyLogo from '../../../assets/companyLogo.png';
import CompanyName from '../../components/CompanyName';
import BackArrow from 'assets/BackArrow';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { actions, sliceKey } from './slice';
import { actions as playerActions } from '../../../redux/playerIdSlice';
import { useInjectSaga } from 'utils/redux-injectors';
import { playerIdSelector } from 'redux/selector';
import { useResendEmailSaga } from './saga';

type Props = {};


const ConfirmRegistration = (props: Props) => {

  useInjectSaga({
    key: sliceKey,
    saga: useResendEmailSaga,
  });

  const styles = useStyles();
  const dispatch = useDispatch();
  const goBack = () => {
    dispatch(playerActions.changePlayerId(undefined))
  }

  const playerId = useSelector(playerIdSelector);
  const handleResendLink = () => {
    dispatch(actions.resendEmailData(playerId))
  }

  return (
    <>
      <Box display="flex" alignItems={'center'} className={styles.WelcomeScreenContainer}>
        <Grid container>
          <Grid item xs={12} sm={12} className={styles.Companylogo}>
            <img src={CompanyLogo} alt="" />
          </Grid>
          <Grid item xs={12} sm={12} sx={{ mt: 10 }} style={{ textAlign: 'center', marginTop: '10px' }}>
            <CompanyName />
          </Grid>
          <Grid item xs={12} sm={12} style={{ textAlign: 'center' }}>
            <div className={styles.ParaContainer}>
              <p>Check your email for your personal link to begin the challenge.</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} className={styles.ButtonContainer}>
            <Button variant={'outlined'} className={styles.ResendLinkBtn} onClick={handleResendLink}>
              Resend Link
            </Button>
            <Button variant={'text'}
              startIcon={<BackArrow />}
              className={styles.ButtonWithLogo}
              onClick={goBack}
            >
              Go Back
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ConfirmRegistration;
