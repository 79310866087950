import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    Companylogo: {
        '& img': {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '10%',
            width: '20%',
            marginBottom: '10px'
        }
    },
    CustomeButton: {
        padding: '10px 30px',
        background: '#8BC4C1',
        color: '#1F1F1E',
        borderRadius: '0px',
        width: '90%',
        fontWeight: '600',
        fontSize: '18px',
        marginTop: '10%',
        '&:hover': {
            background: '#8BC4C1',
            opacity: '1.0'
        }
    },
    FooterClass: {
        marginTop: '15%',
        width: '100%',
        position: 'relative',
        bottom: '10px'
    },
    Heading: {
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '100%',
        letterSpacing: '0.2em',
        textTransform: 'uppercase',
        color: '#ECFAEC',
        opacity: 0.5,
        marginBottom: '0px',
    },
    ParaContainer: {
        width: '75%',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        '& p': {
            paddingTop: 0,
            paddingBottom: 0,
        }
    }
}))