import { getDefaultHeadersChloe } from 'utils/helpers';
import { request } from 'utils/request';
import { APP_API_URL } from 'utils/constants';
import { put, select, takeLatest } from 'redux-saga/effects';

import { actions as alertBoxActions } from '../../../redux/alertSlice'
import { actions as isLoadingActions } from '../../../redux/isLoadingSlice'

import { actions } from './slice';
import { playerIdSelector } from 'redux/selector';

export function* resendEmail(action) {
  const userId = yield select(playerIdSelector);
  yield put(isLoadingActions.changeisLoading(true));
  try {
    const options = {
      method: 'POST',
      headers: getDefaultHeadersChloe(),
      body: JSON.stringify(action.payload)
    }
    const response = yield request(
      `${APP_API_URL}/users/${userId}/resend`,
      options
    );
    if (response) {
      yield put(isLoadingActions.changeisLoading(false));
      yield put(alertBoxActions.changeAlertBox({ open: true, message: 'Link Resent Successfully.', variant: 'success' }));
    } else {
      yield put(isLoadingActions.changeisLoading(false));
      yield put(alertBoxActions.changeAlertBox({ open: true, message: "Something went wrong.", variant: 'error' }));
    }
  } catch (e: any) {
    yield put(isLoadingActions.changeisLoading(false));
    yield put(alertBoxActions.changeAlertBox({ open: true, message: "Something went wrong.", variant: 'error' }));
  }
}

export function* useResendEmailSaga() {
  yield takeLatest(actions.resendEmailData.type, resendEmail);
  // yield takeLatest(actions.sendRegistrationData.type, resendEmail);
}