import React, { useEffect, useState } from 'react';

const Timer = () => {
    const [timeLeft, setTimeLeft] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const time = timeLeft === 60 ? 0 : (timeLeft + 1);
            setTimeLeft(time)
        }, 1000);
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeLeft]);

    // const result2 = new Date(timeLeft * 1000).toISOString().slice(14, 19);

    return (
        <>
            <p>{`Last updated ${timeLeft} seconds ago`}</p>
        </>
    )
};

export default Timer;
