import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import Chloe from '../../../assets/Chloe.png';

type Props = {

};

const useStyles = makeStyles(() => ({
    companyImage: {
        width: '20%'
    },
    h6Styles: {
        margin: '0px',
        padding: '0px',
        color: '#E84D09',
        fontWeight: 400,
    }

}))

const CompanyName = (props: Props) => {
    const styles = useStyles();
    const { t } = useTranslation();
    return <>
        <img className={styles.companyImage} src={Chloe} alt='' />
        <h6 className={styles.h6Styles}>Embryo Challenge</h6>
    </>
};

export default CompanyName;
