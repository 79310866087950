import { getDefaultHeaders } from 'utils/helpers';
import { request } from 'utils/request';
import { API_URL } from 'utils/constants';
import { put, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { actions as alertBoxActions } from '../../../redux/alertSlice'
import { actions as isLoadingActions } from '../../../redux/isLoadingSlice'

export function* getDashboardStats(action) {
  yield put(isLoadingActions.changeisLoading(true));
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/games/score/${action.payload}`,
      options,
    );
    yield put(actions.getDashboardStatsSuccess(response))
    yield put(isLoadingActions.changeisLoading(false));
  } catch (e) {
    yield put(isLoadingActions.changeisLoading(false));
  }
}

export function* requestADemoOfChloe(action) {
  yield put(isLoadingActions.changeisLoading(true));
  try {
    const options = {
      method: 'POST',
      headers: getDefaultHeaders(),
    };
    const response = yield request(
      `${API_URL}/usersrequest/${action.payload}`,
      options,
    );
    yield put(isLoadingActions.changeisLoading(false));
    yield put(alertBoxActions.changeAlertBox({ open: true, message: response.msg, variant: 'success' }));
  } catch (e) {
    yield put(isLoadingActions.changeisLoading(false));
    yield put(alertBoxActions.changeAlertBox({ open: true, message: "Something went wrong.", variant: 'error' }));
  }
}

export function* useScoreCardSaga() {
  yield takeLatest(actions.getDashboardStats.type, getDashboardStats);
  yield takeLatest(actions.requestADemoForChloe.type, requestADemoOfChloe);
}
