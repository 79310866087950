import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ScoreCardState } from './types';
import { EmbryoState } from '../Embryo/types';

export const initialState: ScoreCardState = {
  loading: false,
  snackbar: {
    open: false,
    message: '',
    variant: 'success',
  },
  stats: {} as EmbryoState,
};

export const useScoreCardSlice = createSlice({
  name: 'scoreState',
  initialState,
  reducers: {
    getDashboardStats: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    getDashboardStatsSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.stats = action.payload;
    },
    requestADemoForChloe: (state, action: PayloadAction<any>) => {
      return state;
    }
  },
});

export const { reducer, actions, name: sliceKey } = useScoreCardSlice;
