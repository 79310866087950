import { makeStyles } from '@mui/styles';
// import BgShape from '../../../assets/bg-shape.png';

export const useStyles = makeStyles(() => ({
    Companylogo: {
        '& img': {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '15%',
            width: '20%',
            marginBottom: '10px'
        }
    },
    WelcomeScreenContainer: {
        position: 'absolute',
        top: '20%'
    },
    CustomeButton: {
        padding: '10px 30px',
        background: '#8BC4C1',
        color: '#1F1F1E',
        borderRadius: '0px',
        width: '90%',
        fontWeight: '600',
        fontSize: '18px',
        '&:hover': {
            background: '#8BC4C1',
            opacity: '0.2'
        }
    },
    CalculatingInfo: {
        textAlign: 'center',
        marginTop: '10px',
        fontSize: '18px',

        lineHeight: '130%',
        '& h4': {
            fontWeight: 600,
            marginTop: 0,
            marginBottom: 0,
        },
        '& p': {
            fontWeight: 400,
            marginTop: 0,
            marginBottom: 0,
        }
    },
    DidYouKnowPara: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '130%',
        color: '#ECFAEC',
    },
    DidYouKnowContainer: {
        width: '90%',
        position: 'absolute',
        bottom: '0px',
        left: '20px'
    }
}))