import React, { ChangeEvent, useState, useEffect } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';

import CompanyNameWithLogo from 'app/components/CompanyNameWithLogo';
import { emailValidator, nameValidator } from 'utils/helpers';
import { useStyles } from './styles';
import { actions, reducer, sliceKey } from './slice';
import { reducer as playerIdReducer, sliceKey as playerIdSliceKey } from '../../../redux/playerIdSlice';
import { useDispatch } from 'react-redux';

import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { useRegistrationSaga } from './saga';
import { useSelector } from 'react-redux';
import { sendRegistrationData } from './selector';

type Props = {};

function Registration(props: Props) {

  useInjectReducer({
    key: sliceKey,
    reducer: reducer,
  });

  useInjectReducer({
    key: playerIdSliceKey,
    reducer: playerIdReducer,
  });

  useInjectSaga({
    key: sliceKey,
    saga: useRegistrationSaga,
  });

  const styles = useStyles();
  const dispatch = useDispatch();
  const formData = useSelector(sendRegistrationData);
  const [form, setForm] = useState<any>({
    name: { value: "", error: true },
    email: { value: "", error: true },
    nickName: { value: "", error: true },
    company: { value: "", error: false },
    position: { value: "", error: false },
  });

  useEffect(() => {
    console.log(formData, "This is form data")
    setForm({
      name: { value: formData.name, error: formData.name.length > 0 ? false : true },
      email: { value: formData.email, error: formData.email.length > 0 ? false : true },
      nickName: { value: formData.nickName, error: formData.nickName.length > 0 ? false : true },
      company: { value: formData.company, error: false },
      position: { value: formData.position, error: false },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = () => {
    dispatch(actions.sendRegistrationData({
      name: form.name.value,
      email: form.email.value,
      nickName: form.nickName.value,
      company: form.company.value,
      position: form.position.value
    }))
  }

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const value = event.target.value || ''
    const re = /^[A-Z& ]*$/i;
    let error = !value || (name === 'email' && !emailValidator(value)) || (name === 'name' && !nameValidator(value))
    if (name === "position" && value && !re.test(value)) {
      return
    }
    setForm({ ...form, [name]: { error, value } })
  }

  return (
    <>
      <Box display="flex" alignItems={'center'} className={styles.WelcomeScreenContainer}>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <div className={styles.TextContainer}>
              <CompanyNameWithLogo />
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box className={styles.TextParaComtainer}>
              <p>Please fill in the information below. You will receive a personal link to start the challenge.</p>
            </Box>
          </Grid>
          <Grid item lg={12} sx={{ p: 2 }} style={{ width: '100%' }}>
            <Typography
              style={{ marginBottom: '8px', textTransform: 'uppercase' }}
              component="span"
              className={styles.PlaceholderStyles}
            >
              Name
            </Typography>
            {form.name.error &&
              (<Typography
                style={{ float: 'right', }}
                component="span"
                className={styles.ErrorMessage}
              >
                {!form.name.value ? 'Required' : 'Invalid Name'}
              </Typography>)
            }
            <TextField
              sx={{ mb: 0, }}
              variant="outlined"
              required
              fullWidth
              id="name"
              placeholder=""
              value={form.name.value}
              name="name"
              onChange={handleFieldChange}
            />
          </Grid>

          <Grid item lg={12} sx={{ p: 2 }} style={{ width: '100%' }}>
            <Typography
              style={{ marginBottom: '8px', textTransform: 'uppercase' }}
              component="span"
              className={styles.PlaceholderStyles}
            >
              Email
            </Typography>
            {
              form.email.error &&
              <Typography
                style={{ float: 'right', }}
                component="span"
                className={styles.ErrorMessage}
              >
                {!form.email.value ? 'Required' : 'Invalid Email'}
              </Typography>}
            <TextField
              sx={{ mb: 0, }}
              variant="outlined"
              required
              fullWidth
              id="email"
              placeholder=""
              name="email"
              value={form.email.value}
              onChange={handleFieldChange}
            />
          </Grid>

          <Grid item lg={12} sx={{ p: 2 }} style={{ width: '100%' }}>
            <Typography
              style={{ marginBottom: '8px', textTransform: 'uppercase' }}
              component="span"
              className={styles.PlaceholderStyles}
            >
              Nickname
            </Typography>
            {form.nickName.error &&
              (<Typography
                style={{ float: 'right', }}
                component="span"
                className={styles.ErrorMessage}
              >
                Required
              </Typography>)
            }
            <TextField
              sx={{ mb: 0, }}
              variant="outlined"
              required
              fullWidth
              id="nickName"
              placeholder=""
              name="nickName"
              value={form.nickName.value}
              onChange={handleFieldChange}
            />
          </Grid>

          <Grid item lg={12} sx={{ p: 2 }} style={{ width: '100%' }}>
            <Typography
              style={{ marginBottom: '8px', textTransform: 'uppercase' }}
              component="span"
              className={styles.PlaceholderStyles}
            >
              Company
            </Typography>

            <TextField
              sx={{ mb: 0, }}
              variant="outlined"
              required
              fullWidth
              id="company"
              placeholder=""
              name="company"
              value={form.company.value}
              onChange={handleFieldChange}
            />
          </Grid>

          <Grid item lg={12} sx={{ p: 2 }} style={{ width: '100%' }}>
            <Typography
              style={{ marginBottom: '8px', textTransform: 'uppercase' }}
              component="span"
              className={styles.PlaceholderStyles}
            >
              Position
            </Typography>

            <TextField
              sx={{ mb: 0, }}
              variant="outlined"
              required
              fullWidth
              id="position"
              placeholder=""
              name="position"
              value={form.position.value}
              onChange={handleFieldChange}
              inputProps={{ maxLength: 50, }}
            />
          </Grid>

          <Grid item xs={12} sm={12} className={styles.ButonAlignment}>
            <Button variant={'contained'} disabled={form.name.error || form.email.error || form.nickName.error} onClick={handleSubmit}>
              Send my link
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Registration;
