import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState as initialStateAlertBox } from './alertSlice';
import { initialState as initialStateIsLoading } from './isLoadingSlice';
import { initialState as initialStatePlayerId } from './playerIdSlice';
import { initialState as initialStateuserId } from './userIdSlice';

const selectDomainAlertBox = (state: RootState) => state.alertBox || initialStateAlertBox
const selectDomainIsLoading = (state: RootState) => state.isLoading || initialStateIsLoading
const selectDomainUserId = (state: RootState) => state.playerId || initialStatePlayerId
const selectDomainId = (state: RootState) => state.userId || initialStateuserId
const initialState = (state: RootState) => state

export const alertBox = createSelector([selectDomainAlertBox], state => state.alertBox)
export const setIsLoading = createSelector([selectDomainIsLoading], state => state.isLoading)
export const playerIdSelector = createSelector([selectDomainUserId], state => state.playerId)
export const idSelector = createSelector([selectDomainId], state => state.userId)
export const allState = createSelector([initialState], state => state)
