import React, { useEffect } from "react";
import { Alert, AlertColor } from "@mui/material"
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { alertBox } from "redux/selector";
import { actions, reducer } from "redux/alertSlice";
import { useInjectReducer } from "redux-injectors";

export interface AlertBoxProps {
    message: string;
    variant?: AlertColor;
    onClose: any;
}

export type SnackbarProp = AlertBoxProps;
const useStyles = makeStyles(() => ({
    root: {
        top: 140,
        backgroundColor: '#E84D09'
    },
    AlertStyles: {
        position: 'fixed',
        top: '10px',
        right: 0,
        // backgroundColor: '#E84D09'
    }
}))


const AlertBox = () => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const alert: { open: boolean, variant: AlertColor, message: string } = useSelector(alertBox);
    useInjectReducer({ key: 'alertBox', reducer: reducer });

    useEffect(()=>{
        const timer = setTimeout(() => {
            dispatch(actions.changeAlertBox(false))
        }, 3000);
        return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[alert.open])

    return (
        <>
            {alert.open &&
                <Alert
                    className={styles.AlertStyles}
                    severity={alert.variant}
                    variant={"filled"}
                    onClose={() => dispatch(actions.changeAlertBox({ open: false, message: "", variant: 'error' }))}
                >
                    {alert.message}
                </Alert>
            }
        </>
    )
}
export default AlertBox;