import { NoEncryption } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
// import BgShape from '../../../assets/bg-shape.png';
import theme from 'styles/theme/mui';

export const useStyles = makeStyles(() => ({
    Companylogo: {
        '& img': {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '15%',
            width: '20%',
            marginBottom: '10px'
        }
    },
    WelcomeScreenContainer: {

    },
    CustomeButton: {
        padding: '10px 30px',
        background: '#8BC4C1',
        color: '#1F1F1E',
        borderRadius: '0px',
        width: '90%',
        fontWeight: '600',
        fontSize: '18px',
        '&:hover': {
            background: '#8BC4C1',
            opacity: '0.2'
        }
    },
    FooterClass: {
        position: 'absolute',
        bottom: '10px',
        width: '100%',
        marginTop: '10%'
    },
    ButtonWithLogo: {
        fontSize: '18px',
        fontWeight: 400,
        marginTop: '8%',
        color: 'rgba(236, 250, 236, 0.5)',
        '&:hover': {
            background: 'transparent',
            border: 'none',
            color: 'rgba(236, 250, 236, 0.5)',
        },
        '&:active': {
            background: 'transparent',
            border: 'none',
            color: 'rgba(236, 250, 236, 0.5)',
        }
    },
    ParaContainer: {
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '40px'
    },
    ButtonContainer: {
        textAlign: 'center',
        marginTop: '20px'
    },
    ResendLinkBtn: {
        border: '1px solid rgba(236, 250, 236, 0.2)',
        color: '#ECFAEC',
        fontWeight: '600',
        fontSize: '20px'
    }
}))