import { makeStyles } from '@mui/styles';
// import BgShape from '../../../assets/bg-shape.png';
import theme from 'styles/theme/mui';

export const useStyles = makeStyles(() => ({
    companyImage: {
        width: '20%'
    },
    h6Styles: {
        margin: '0px',
        padding: '0px',
        color: '#E84D09',
        fontWeight: 400,
    },
    Container: {
        fontFamily: 'Archia',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '32px',
        lineHeight: '38px',
        display: 'flex',
        alignItems: 'flex-end',
        '& .MuiTableCell-root': {
            fontSize: '32px !important',
            // border: '0 0 1px 0 solid #4B615F !important'
        },
    }
}))