import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../slice';
import { useStyles } from '../styles';

interface TimerPropos {
    seconds: number;
    direction: string;
    embryoId: string;
}

const Timer = ({ direction, embryoId }: TimerPropos) => {
    const [timeLeft, setTimeLeft] = useState(0);
    const [embrId, setEmbrId] = useState("");
    const styles = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        if (embrId) {

        }
        setEmbrId(embryoId);
        setTimeLeft(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [embryoId])


    useEffect(() => {
        if (direction === 'reverse' && !timeLeft) return;
        const intervalId = setInterval(() => {

            const time = direction === 'reverse' ? (timeLeft - 1) : (timeLeft + 1);
            setTimeLeft(time)
            dispatch(actions.updateEmbryoDetailsTime({ id: embrId, timeLeft: time }))
        }, 1000);
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeLeft]);

    const result2 = new Date(timeLeft * 1000).toISOString().slice(14, 19);

    return (
        <div className={styles.ParaDiv}><p>Time elapsed:</p>
            <span className={styles.Timer}>
                {result2}
            </span>
        </div>
    )
};

export default Timer;