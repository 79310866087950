import { getDefaultHeaders } from 'utils/helpers';
import { request } from 'utils/request';
import { API_URL } from 'utils/constants';
import { delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { actions as alertBoxActions } from '../../../redux/alertSlice';
import { actions as embryoActions } from '../Embryo/slice';
import { actions as isLoadingActions } from '../../../redux/isLoadingSlice';
import { embroysItem } from './selector';


export function* getEmbryoList(action) {
  yield put(isLoadingActions.changeisLoading(true));
  try {
    const options = {
      method: 'GET',
      headers: getDefaultHeaders(),
    };
    const userId = action.payload;
    const response = yield request(
      `${API_URL}/games/${userId}`,
      options,
    );
    if (response) {
      if (response && response.msg && response.msg.toLowerCase().includes("not found")) {
        yield put(alertBoxActions.changeAlertBox({ open: true, message: response?.msg, variant: 'error' }));
        yield put(isLoadingActions.changeisLoading(false));
        return
      }
      yield put(embryoActions.setEmbryoItem(response))
      yield put(isLoadingActions.changeisLoading(false));
      return
    } else {
      yield put(isLoadingActions.changeisLoading(false));
      yield put(alertBoxActions.changeAlertBox({ open: true, message: "Something went wrong.", variant: 'error' }));
      return
    }
  } catch (e) {
    yield put(isLoadingActions.changeisLoading(false));
    yield put(alertBoxActions.changeAlertBox({ open: true, message: "Something went wrong.", variant: 'error' }));
  }
}
export function* putEmbryoList(action) {
  yield put(isLoadingActions.changeisLoading(true));
  yield delay(500)
  const state = yield select(embroysItem)
  try {
    const options = {
      method: 'PUT',
      headers: getDefaultHeaders(),
      body: JSON.stringify({
        ...state
      })
    };
    // const userId = action.payload;
    const response = yield request(
      `${API_URL}/games`,
      options,
    );
    if (response) {
      if (response && response.msg && response.msg.toLowerCase().includes("not found")) {
        yield put(alertBoxActions.changeAlertBox({ open: true, message: response?.msg, variant: 'error' }));
        yield put(isLoadingActions.changeisLoading(false));
        return
      }
      yield put(embryoActions.updateScorecard(response))
      yield put(isLoadingActions.changeisLoading(false));
      return
    } else {
      yield put(isLoadingActions.changeisLoading(false));
      yield put(alertBoxActions.changeAlertBox({ open: true, message: "Something went wrong.", variant: 'error' }));
      return
    }
  } catch (e) {
    yield put(isLoadingActions.changeisLoading(false));
    yield put(alertBoxActions.changeAlertBox({ open: true, message: "Something went wrong.", variant: 'error' }));
  }
}


export function* useEmbryoSaga() {
  yield takeLatest(actions.getEmbryoItemList.type, getEmbryoList);
  yield takeLatest(actions.putEmbryoList.type, putEmbryoList);
}
