import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: 'rgba(236, 250, 236, 0.1)',
    },
    [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: '#009898',
    },
}));

type Props = {
    CurrentVideo: number,
    TotalVideos: number,
};

const LinearProgressBar = ({ CurrentVideo, TotalVideos }: Props) => {
    const progressBarValue = CurrentVideo * 100 / TotalVideos;
    return (
        <Box sx={{ flexGrow: 1, px: 2 }}>
            <BorderLinearProgress variant="determinate" value={progressBarValue} />
        </Box>
    );
}
export default LinearProgressBar