import React from "react";
import { Alert, AlertColor, Box, CircularProgress } from "@mui/material"
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { setIsLoading } from "redux/selector";
import { reducer } from "redux/isLoadingSlice";
import { useInjectReducer } from 'redux-injectors';

export interface AlertBoxProps {
    message: string;
    variant?: AlertColor;
    onClose: any;
}

export type SnackbarProp = AlertBoxProps;
const useStyles = makeStyles(() => ({
    root: {
        top: 140,
        backgroundColor: '#E84D09'
    },
    AlertStyles: {
        position: 'absolute',
        top: '10px',
        right: 0,
        backgroundColor: '#E84D09'
    }
}))


const Spinner = () => {
    const styles = useStyles();

    useInjectReducer({ key: 'isLoading', reducer: reducer });
    const loading = useSelector(setIsLoading);

    return (
        <>
            {loading &&
                (<Box sx={{
                    display: 'flex', width: '100%', height: '100%',
                    alignItems: "center",
                    justifyContent: "center",
                    position: "fixed",
                    top: "0",
                    left: "0",
                    backgroundColor: "rgba(0, 0, 0, 0.37)",
                    zIndex: "9999",
                }}>
                    <CircularProgress />
                </Box>)
            }
        </>
    )
}
export default Spinner;