import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import CompanyNameWithLogo from 'app/components/CompanyNameWithLogo';
import LinearProgressBar from 'app/components/LinearProgressBar';
import CalculationGif from '../../../assets/CalculationGif.gif';
import Right from 'assets/Right.png';
import VideoPlayer from './commonComponents/VideoPlayer';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { embroysItem } from './selector';
import Timer from './commonComponents/Timer';
import { Embryo as EmbryoStateType } from './types';
import { actions, reducer, sliceKey } from './slice';
import { actions as loaderAction, reducer as loaderReducer, sliceKey as loaderSlice } from '../../../redux/isLoadingSlice';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { useEmbryoSaga } from './saga';
import { idSelector } from 'redux/selector';


type Props = {};

const Embryo = (props: Props) => {
  const styles = useStyles();

  useInjectReducer({
    key: sliceKey,
    reducer: reducer,
  });

  useInjectReducer({
    key: loaderSlice,
    reducer: loaderReducer,
  });

  useInjectSaga({
    key: sliceKey,
    saga: useEmbryoSaga,
  });

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const embroysDetails = useSelector(embroysItem);
  const userId = useSelector(idSelector);
  const embroyList = embroysDetails.embroys;

  const [showChloe, setShowChloe] = useState(false);
  const [stat, setStat] = useState({
    video: 0,
    score: 0,
  });
  const [embroyItem, setEmbroyItem] = useState<EmbryoStateType | null>(null);
  useEffect(() => {
    if (!userId) {
      navigate(-1)
    }

  }, [])

  useEffect(() => {
    const embroyListItem = embroyList[stat.video];
    setEmbroyItem(embroyListItem)
    setShowChloe(false)
    // dispatch(loaderAction.changeisLoading(true))
    // const timer = setTimeout(() => {
    //   dispatch(loaderAction.changeisLoading(false))
    // }, 1000);
    // return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stat])

  const handleSubmit = (e: any, type: string) => {
    setStat({
      ...stat,
      video: stat.video + 1,
      score: type === 'transfer' ? stat.score + 1 : stat.score
    })
    const updatedValue = {
      id: embroyItem?.embroy_id,
      response: type === 'transfer' ? true : false,
    }
    dispatch(actions.updateEmbryoDetail(updatedValue))
    if (stat.video === embroyList.length - 1) {
      let path = '/calculating';
      navigate(path);
    }
  }

  return (
    <>
      {embroyItem &&
        (
          <Box display="flex" alignItems={'center'} className={styles.WelcomeScreenContainer}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <div className={styles.TextContainer}>
                  <CompanyNameWithLogo />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} sx={{ mt: 4 }}>
                <LinearProgressBar CurrentVideo={stat.video + 1} TotalVideos={embroyList.length} />
                <Box className={styles.ProgressBarStatus}>
                  <p>Embryo {stat.video + 1} of {embroyList.length}</p>
                  <Timer
                    seconds={embroyItem.time_in_sec}
                    direction={'forward'}
                    embryoId={embroyItem.embroy_id}
                  />
                </Box>
              </Grid>

              <VideoPlayer
                embryoId={embroyItem.embroy_id}
                Sources={embroyItem.video_link}
                Score={embroyItem.chloe_eq_score}
                ThumbnailImg={embroyItem.last_image_link}
              />

              <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                <Box className={styles.ButtonContainer}>
                  <Button className={styles.Transfer} onClick={(e) => handleSubmit(e, 'transfer')}>Transfer/Cryo</Button>
                  <Button className={styles.Discard} onClick={(e) => handleSubmit(e, 'discard')}>Discard</Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} className={styles.ButonAlignment}>
                <Accordion
                  disableGutters
                  onChange={() => setShowChloe(!showChloe)}
                  expanded={showChloe}
                  elevation={0}
                  className={styles.ScorerSection}
                >
                  <AccordionSummary
                    sx={{ p: 0 }}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {
                        !showChloe ?
                          <img src={CalculationGif} alt='Calculation gif' width={52} height={52} /> :
                          <img src={Right} alt='Ticked' width={40} height={30} />
                      }
                      Chloe Insights
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{ px: 2, textAlign: 'left' }}>
                    <p>
                      {embroyItem.chloe_recommendation}
                    </p>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Box>
        )
      }
      {/* <Footer className={styles.FooterClass} /> */}
    </>
  );
};

export default Embryo;
