import React, { useEffect, useState } from 'react';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { Box, Grid } from '@mui/material';

import { reducer, sliceKey } from './slice';
import { useDashboardSaga } from './saga';
import CalculationGif from '../../../assets/CalculationGif.gif';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { embroysItem } from '../Embryo/selector';
import { actions } from '../Embryo/slice';
import { idSelector } from 'redux/selector';

type Props = {};

const Calculating = (props: Props) => {
  useInjectReducer({
    key: sliceKey,
    reducer: reducer,
  });

  const userId = useSelector(idSelector);

  useInjectSaga({
    key: sliceKey,
    saga: useDashboardSaga,
  });

  const styles = useStyles();

  const [timeLeft, setTimeLeft] = useState(10);
  let navigate = useNavigate();
  useEffect(() => {
    if (!timeLeft) {
      navigate(`/score/${userId}`);
    };
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.putEmbryoList())
  }, [])

  return (
    <>
      <Box alignItems={'center'} className={styles.WelcomeScreenContainer}>
        <Grid container>
          <Grid item xs={12} sm={12} className={styles.Companylogo}>
            <img src={CalculationGif} alt="" />
          </Grid>
          <Grid item xs={12} sm={12} className={styles.CalculatingInfo}>
            <h4>Calculating your score.</h4>
            <p>One moment please.</p>
          </Grid>
        </Grid>
      </Box>
      <Box className={styles.DidYouKnowContainer}>
        <p style={{ color: '#ED7600' }}>Did you know?</p>
        <p className={styles.DidYouKnowPara}>
          Chloe EQ provides transparency into a wide spectrum of clinically relevant biomarkers, derived automatically, so YOU can better understand the biology behind the ranking and selection of each individual embryo.This is Transparent AI!
        </p>
      </Box>
    </>
  );
};

export default Calculating;
