import React from 'react';
import { Box, Grid } from '@mui/material';
import BasicTable from './components/BasicTable';
import CompanyLogoColor from '../../../assets/CompanyLogoColor.png';
import CodeScanner from '../../../assets/qrcode.png';
import LeaderBoardCorner from '../../../assets/LeaderBoardCorner.png';
import ChloeWhite from '../../../assets/ChloeWhite.png';
import { useStyles } from './styles';
import Timer from './components/Timer';

type Props = {};

const Scorecard = (props: Props) => {

  const styles = useStyles();
  const year = new Date().getFullYear();

  return (
    <Box display="flex" alignItems={'center'} className={styles.Container}>
      <Grid container sx={{ mx: 'auto' }}>
        <Grid item xs={6} sm={6} lg={6} xl={6} className={styles.CodeScannerContainer}>
          <Box className={styles.CodeSection}>
            <img src={CodeScanner} alt="" />
            <p>
              Go to <a href='https://eqchallenge.fairtility.com/'>EQchallenge.fairtility.com</a> to test how quickly and accurately you can predict
              implantation outcomes.
            </p>
            <p>
              See how AI can assist you!
            </p>
          </Box>
          <Box className={styles.InfoSection}>
            <img src={ChloeWhite} width='128.78px' height='174px' style={{ marginBottom: 'auto', marginTop: 'auto' }} alt='' />
            <p>We’re here at ESHRE, shoot us a note at meetme@fairtility.com to schedule a meeting with us.</p>
            <img src={LeaderBoardCorner} alt='' height='116.79px' className={styles.LeaderBoardCornerImage} />
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} lg={6} xl={6} className={styles.TableContainer}>
          <p><span>Chloe Challenge</span> Top 10 Players</p>
          <BasicTable />
          <Box className={styles.TableFooter}>
            <div>
              <img src={CompanyLogoColor} alt='Footer logo' />
              <p>© {year} Fairtility</p>
            </div>
            <Timer />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Scorecard;
