import React, { useState } from 'react';

import CompanyLogoColor from './assets/CompanyLogoColor.png';
import { makeStyles } from '@mui/styles';
import { Box, Container } from '@mui/material';
import { useLocation } from 'react-router-dom';

// Create rtl cache
const useStyles = makeStyles(() => ({
    button: {
        height: 63,
    },
    submit: {
        width: 203,
        fontSize: 18,
    },
    cancel: {
        padding: '0px 42px',
        fontSize: 16,
    },
    FooterStyles: {
        textAlign: 'center',
        width: '100%',
        '& img': {
            width: '30px',
            height: '30px'
        },
        '& p': {
            fontSize: '14px !important',
            marginBottom: '0',
        }
    },
    FooterContainer: {
        marginTop: 'auto',
        paddingTop: '20px',
        paddingBottom: '10px',
    }
}));

type Props = {
    className?: any,
    style?: any,
};

const Footer = (props: Props) => {
    const styles = useStyles();
    const year = new Date().getFullYear();
    const location = useLocation();
    const show = location.pathname !== '/calculating';
    return (
        <>
            {
                show && (
                    <Container className={`${props.className} ${styles.FooterContainer}`} style={{ marginTop: 'auto' }}>
                        <Box className={styles.FooterStyles}>
                            <img src={CompanyLogoColor} alt='Footer logo' />
                            <p>© {year} Fairtility</p>
                        </Box>
                    </Container>
                )
            }
        </>
    )
}

export default Footer