import { NoEncryption } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
// import BgShape from '../../../assets/bg-shape.png';
import theme from 'styles/theme/mui';

export const useStyles = makeStyles(() => ({
    Companylogo: {
        '& img': {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '15%',
            width: '20%',
            marginBottom: '10px'
        }
    },
    WelcomeScreenContainer: {

    },
    CustomeButton: {
        padding: '10px 30px',
        background: '#8BC4C1',
        color: '#1F1F1E',
        borderRadius: '0px',
        width: '90%',
        fontWeight: '600',
        fontSize: '18px',
        '&:hover': {
            background: '#8BC4C1',
            opacity: '0.2'
        }
    },
    TextContainer: {
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '40px',
        '& h3': {
            width: '68%',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '24px',
            lineHeight: '29px',
        },
        '& p': {
            marginTop: '5px',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '130%'
        }
    },
    CustomeOutlineButton: {
        padding: '10px 30px',
        border: '1px solid rgba(236, 250, 236, 0.2)',
        color: '#ECFAEC',
        borderRadius: '0px',
        width: '90%',
        fontWeight: '600',
        fontSize: '18px',
        '&:hover': {
            background: '#8BC4C1',
            opacity: '0.2'
        }
    },
    ButonAlignment: {
        textAlign: 'center',
        marginTop: '40px'
    },
    TextParaComtainer: {
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '70%',
        marginTop: '5%'
    },
    FooterClass: {
        marginTop: '10%',
        width: '100%',
        position: 'relative',
        bottom: '10px'
    },
    PlaceholderStyles: {
        fontFamily: 'Archia',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '100%',
        letterSpacing: '0.2em',
        textTransform: 'uppercase',
        color: '#ECFAEC',
    },
    ErrorMessage: {
        fontFamily: 'Archia',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '100%',
        color: '#E84D09',
    }

}))