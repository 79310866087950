import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { actions, reducer, sliceKey } from '../../slice';
import { useTopTenRankSaga } from '../../saga';
import { useDispatch, useSelector } from 'react-redux';
import { topTenListSelector } from '../../selector';
import { useStyles } from './styles';

type Props = {

};

const createData = (
    name: string,
    score: number,
) => ({ name, score });

const BasicTable = (props: Props) => {
    useInjectReducer({
        key: sliceKey,
        reducer: reducer,
    });
    useInjectSaga({
        key: sliceKey,
        saga: useTopTenRankSaga,
    });

    const styles = useStyles();
    const topTenRecords = useSelector(topTenListSelector);
    const dispatch = useDispatch();
    const rows = topTenRecords.map((value, index) => {
        return createData(value.nickname, value.score)
    })

    useEffect(() => {
        dispatch(actions.getTopTenLists())
        const interval = setInterval(() => {
            dispatch(actions.getTopTenLists())
        }, 60000)
        return () => clearInterval(interval)
        // dispatch(actions.getTopTenLists())
    }, [])

    return (
        <>
            <TableContainer className={styles.Container}>
                <Table aria-label="simple table">
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row" sx={{ paddingLeft: '0px', color: '#4B615F !important' }}>
                                    {index + 1}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right" sx={{ paddingRight: '0px' }}>{row.score}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
};

export default BasicTable;
